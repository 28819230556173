import React from "react";

export const CoreValues = (props) => {
  return (
    <div id="mission">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="mission-text">
              <h2>Core Values</h2>
              <p>{props.data ? props.data.paragraph : ""}</p>
              {/* <h3>Why Choose Us?</h3> */}
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.subtitle.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/coreValues.png" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
