import React, { useEffect, useState } from "react";
import { About } from "./components/about";
import { Header } from "./components/header";
import { Navigation } from "./components/navigation";
import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Contact } from "./components/contact";
import { CoreValues } from "./components/coreValues";
import { Mission } from "./components/mission";
import { TechStack } from "./components/techStack";
import { Vision } from "./components/Vvsion";
import JsonData from "./data/data.json";
// import { Helmet, HelmetProvider } from "react-helmet-async";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      {/* <HelmetProvider> */}
      {/* <Helmet>
        <meta
          property="og:image"
          content="https://cntsoftware.vn/img/logoMeta.png"
        />
        <meta
          name="description"
          content="Center Nexus of Technology is where the passion for technology connects the company's members with each other and with its partners. The goal is to create a vibrant, energetic, sustainable environment."
        />
        <meta property="og:title" content="Center Nexus of Technology" />
        <meta name="keywords" content="Center Nexus of Technology" />
        <title>Center Nexus of Technology</title>
      </Helmet> */}
      <Navigation />
      <Header data={landingPageData.Header} />
      {/* <Features data={landingPageData.Features} /> */}
      <About data={landingPageData.About} />
      <Mission data={landingPageData.Mission} />
      <Vision data={landingPageData.Vision} />
      <CoreValues data={landingPageData.CoreValues} />
      <Services data={landingPageData.Services} />
      {/* <Gallery data={landingPageData.Gallery} /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
      <TechStack data={landingPageData.TecchStack} />
      <Contact data={landingPageData.Contact} />
      {/*  </HelmetProvider> */}
    </div>
  );
};

export default App;
